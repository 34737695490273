@use 'sass:map';

@use '@angular/material' as mat;


// Colours - CSS variables

$qpBlack:                           black;
$qpBlue:                            blue;
$qpBlueLight:                       rgba(14, 183, 246, 1); // #ff0eb7f6
$qpBlueMid:                         rgba(57, 73, 171, 0.5); // blue, 50% transparency
$qpGray:                            gray;
$qpGrayDark:                        dimgray;
$qpGrayDark2:                       rgba(48, 48, 48, 1);
$qpGrayDark3:                       rgba(32, 32, 32, 1);
$qpGreen:                           green;
$qpGreena:                          rgb(3, 112, 68);   // ff037044;
$qpGreenDark:                       rgba(3, 112, 68, 1);   // ff037044
$qpGreenLight:                      lightgreen;
$qpOrange:                          rgba(234, 95, 16, 1);  // #ffea5f39
$qpRedDark:                         darkred;
$qpPinkLight:                       lightpink;
$qpRed:                             red;
$qpTomato:                          tomato;
$qpYellow:                          yellow;
$qpYellowDark:                      rgba(177, 133, 16, 1); // #ffB18516
$qpWhite:                           white;


// $themePrimary:                        mat.get-theme-color($theme, primary, default);
// $themeAccent:                         mat.get-theme-color($theme, accent,  default);
// $themeAccent:                         mat.get-theme-color($theme, warn,    default);

$button-hover:                        $qpBlueMid;
$table-hover:                         $qpBlueMid;
// themePrimary;
// $qpBlueMid;

$window-background-dark:              $qpGrayDark3;//$qpBlack;
$window-app-sidebar-background-dark:  $qpGrayDark2;//$qpGrayDark3;
// $text-dark:                          $qpWhite;

$window-background-light:             $qpWhite;
$window-app-sidebar-background-light: $qpGray;
// $text-light:                         $qpBlack;

$window-app-header-background:        $qpBlack;
$window-app-footer-background:        $window-app-header-background;


:root {
    // Base colours
    --sfnBlack:                       #{$qpBlack};
    --sfnBlue:                        #{$qpBlue};
    --sfnBlueLight:                   #{$qpBlueLight};
    --sfnBlueMid:                     #{$qpBlueMid};
    --sfnGray:                        #{$qpGray};
    --sfnGrayDark:                    #{$qpGrayDark};
    --sfnGreen:                       #{$qpGreen};
    --sfnGreena:                      #{$qpGreena};
    --sfnGreenDark:                   #{$qpGreenDark};
    --sfnGreenLight:                  #{$qpGreenLight};
    --sfnOrange:                      #{$qpOrange};
    --sfnRedDark:                     #{$qpRedDark};
    --sfnPinkLight:                   #{$qpPinkLight};
    --sfnRed:                         #{$qpRed};
    --sfnTomato:                      #{$qpTomato};
    --sfnYellow:                      #{$qpYellow};
    --sfnYellowDark:                  #{$qpYellowDark};
    --sfnWhite:                       #{$qpWhite};

    --window-app-header-background:   #{$window-app-header-background};
    --window-app-footer-background:   #{$window-app-footer-background};
    --window-app-sidebar-background:  #{$window-app-sidebar-background-dark};


    // Note: the contents below *MUST* match those in the
    // (Java) colours XML file (./res/values/color.xml)

    // Custom colours - TBD
    --sfncdma:                        rgba(234, 95,  57,  1); // 'ffea5f39' //rgba(148,  85,  85,  1); //'#945555';
    --sfngsm:                         rgba(255, 255, 102, 1); //'#00ff99';
    --sfnlte:                         rgba(14,  183, 246, 1); //'#0eb7f6';
    --sfnnr:                          rgba(234, 95,  57,  1); // 'ffea5f39' //rgba(148,  85,  85,  1); //'#945555';
    --sfntdscdma:                     rgba(255, 255, 102, 1); //'#00ff99';
    --sfnumts:                        rgba(0,   255, 153, 1); //'#ffff66';


    // Generic colours
    --none:                           var(#{--sfnGray});
    --unknown:                        var(#{--sfnGray});


    // Button colours
    --button-hover:                   #{$button-hover};
    --button-active:                  var(--sfnGreenLight);//var(#{--sfnGreenLight});
    // rgba(57, 73, 171, 1);   // blue


    // Tab colours
    --tab-icon:                       var(#{--sfnWhite});
    --tab-icon-active:                var(#{--sfnBlueLight});


    // Table colours
    // --table-hover:                    #{$table-hover}; // See below


    // Window colours
    // --window-background:              var(#{--sfnBlack});
    --window-background:              #{$window-background-dark};
    --text:                           var(#{--sfnWhite});


    // Dashboard colours
    --dashboard-icon:                 var(#{--sfnWhite});


    // Device battery icon colours
    --device-battery-good:            var(#{--sfnGreenLight});
    --device-battery-average:         var(#{--sfnYellow});
    --device-battery-poor:            var(#{--sfnTomato});
    --device-battery-none:            var(#{--none});

    // Device power icon colours
    --device-power-off:               var(#{--sfnTomato});
    --device-power-on:                var(#{--sfnGreenLight});
    --device-power-reset:             var(#{--sfnYellow});
    --device-power-unknown:           var(#{--unknown});

    // Device test colours
    --device-test-running:            var(#{--sfnGreenLight});
    --device-test-not-running:        var(#{--sfnTomato});
 
    // Generic result colours
    --result-great:                   var(#{--sfnGreen});//var(#{--sfnGreenDark});
    --result-good:                    var(#{--sfnBlueLight});//var(#{--sfnBlue});
    --result-average:                 var(#{--sfnYellow});//var(#{--sfnYellowDark});
    --result-poor:                    var(#{--sfnOrange});
    --result-bad:                     var(#{--sfnRed});//var(#{--sfnRedDark});
    --result-none:                    var(#{--none});

    // Generic signal colours
    --signal-great:                   var(#{--sfnGreen});//var(#{--sfnGreenDark});
    --signal-good:                    var(#{--sfnBlueLight});//var(#{--sfnBlue});
    --signal-average:                 var(#{--sfnYellow});//var(#{--sfnYellowDark});
    --signal-poor:                    var(#{--sfnOrange});
    --signal-bad:                     var(#{--sfnRed});//var(#{--sfnRedDark});
    --signal-none:                    var(#{--none});

       
    // Mobile cell level colours
    --mobilecell-level-great:         var(#{--signal-great});
    --mobilecell-level-good:          var(#{--signal-good});
    --mobilecell-level-average:       var(#{--signal-average});
    --mobilecell-level-poor:          var(#{--signal-poor});
    --mobilecell-level-bad:           var(#{--signal-bad});
    --mobilecell-level-none:          var(#{--signal-none});


    // Mobile cell technology colours
    --mobilecell-technology-auto:     var(#{ --mobilecell-technology-unknown});
    --mobilecell-technology-cdma:     var(#{--signal-poor});
    --mobilecell-technology-gsm:      var(#{--signal-bad});
    --mobilecell-technology-lte:      var(#{--signal-average});
    --mobilecell-technology-nr:       var(#{--signal-great});
    --mobilecell-technology-nr-nsa:   var(#{--signal-good});
    --mobilecell-technology-td-scdma: var(#{--signal-average});
    --mobilecell-technology-umts:     var(#{--signal-poor});
    --mobilecell-technology-unknown:  var(#{--signal-none});


    // Notitication colours
    --notification-high:              var(#{--sfnTomato});
    --notification-low:               var(#{--sfnGreenLight});
    --notification-medium:            var(#{--sfnYellow});
    --notification-none:              var(#{--none});


    // Status colours
    --status-good:                    var(#{--sfnGreenLight});
    --status-average:                 var(#{--sfnYellow});
    --status-poor:                    var(#{--sfnTomato});
    --status-none:                    var(#{--none});


    // Status message colours
    --status-message-error:           var(#{--sfnPinkLight});
    --status-message-warn:            var(#{--sfnYellowDark});
    --status-message-info:            var(#{--sfnGreenLight});
    --status-message-none:            var(#{--none});


    // Survey colours
    --survey-completed:               var(#{--sfnGreenLight});
    --survey-paused:                  var(#{--sfnYellow});
    --survey-started:                 var(#{--sfnRed});
    --survey-stopped:                 var(#{--sfnGreenLight});
    --survey-unknown:                 var(#{--unknown});


    // Wifi version colours
    --wifi-connected:                 var(#{--sfnGreenLight});
    --wifi-standard-ac:               var(#{--signal-average});
    --wifi-standard-ad:               var(#{--signal-great});
    --wifi-standard-ax:               var(#{--signal-good});
    --wifi-standard-be:               var(#{--signal-great});
    --wifi-standard-n:                var(#{--signal-poor});
    --wifi-standard-legacy:           var(#{--signal-poor});
    --wifi-standard-none:             var(#{--signal-none});
}



// Mobile technology colours
// [TBD] needed here as component css not picked up
.cdma {
    color: var(--mobilecell-technology-cdma);
}

.gsm {
    color: var(--mobilecell-technology-gsm);
}

.lte {
    color: var(--mobilecell-technology-lte);
}

.nr {
    color: var(--mobilecell-technology-nr);
}

.nr_nsa {
    color: var(--mobilecell-technology-nr-nsa);
}

.td_scdma {
    color: var(--mobilecell-technology-td-scdma);
}

.umts {
    color: var(--mobilecell-technology-umts);
}

.unknown {
    color: var(--mobilecell-technology-unknown);
}


.link {
    color: var(--sfnBlueLight);
}


// Mobile signal colours
// [TBD] needed here as component css not picked up
.mobile-signal-great {
    color: var(--mobilecell-level-great);
}

.mobile-signal-good {
    color: var(--mobilecell-level-good);
}

.mobile-signal-average {
    color: var(--mobilecell-level-average);
}

.mobile-signal-poor {
    color: var(--mobilecell-level-poor);
}

.mobile-signal-bad {
    color: var(--mobilecell-level-bad);
}

.mobile-signal-none {
    color: var(--mobilecell-level-none);
}


// Result colours
// [TBD] needed here as component css not picked up
.result-great {
    color: var(--result-great) !important;
}

.result-good {
    color: var(--result-good) !important;
}

.result-average {
    color: var(--result-average) !important;
}

.result-poor {
    color: var(--result-poor) !important;
}

.result-.bad {
    color: var(--result-bad) !important;
}

.result-none {
    color: var(--result-none) !important;
}


// Wifi type colours
.wifi-11ac {
    color: var( --wifi-standard-ac);
}

.wifi-11ad {
    color: var( --wifi-standard-ad);
}

.wifi-11ax {
    color: var( --wifi-standard-ax);
}

.wifi-11be {
    color: var( --wifi-standard-be);
}

.wifi-11n {
    color: var( --wifi-standard-n);
}

.wifi-legacy {
    color: var( --wifi-standard-legacy);
}

.wifi-unknown {
    color: var( --wifi-standard-none);
}

.wifi-5 {
    color: var(--wifi-5);
}

.wifi-6 {
    color: var(--wifi-6);
}

.wifi-7 {
    color: var(--wifi-7);
}

.wifi-connected {
    color: var(--wifi-connected);
}


.mat-mdc-snack-bar-container {
    &.status-message-error {
        --mdc-snackbar-container-color: var(--status-message-error) !important;
        // lightpink !important;
        // --mdc-snackbar-container-color: #2196f3;
        // --mat-mdc-snack-bar-button-color: #fff;
        // --mdc-snackbar-supporting-text-color: #fff;
    }

    &.status-message-warn {
        --mdc-snackbar-container-color: var(--status-message-warn) !important;
        // --mdc-snackbar-container-color: orange !important;
    }

    &.status-message-info {
        --mdc-snackbar-container-color: var(--status-message-info) !important;
        // --mdc-snackbar-container-color: lightgreen !important;
    }

    &.status-message-none {
        --mdc-snackbar-container-color: var(--status-message-none) !important;
    }

    &.status-message-position-top {
        margin-top: var(--app-headerHeight) !important;
        // --mdc-snackbar-container-color: var(--status-message-none) !important;
    }

    &.status-message-position-bottom {
        margin-top: var(--app-footerHeight) !important;
        // --mdc-snackbar-container-color: var(--status-message-none) !important;
    }

}
// ::ng-deep .status-message-error {
//     background-color: lightpink !important;
// }

// ::ng-deep .status-message-warn {
//     background-color: orange !important;
// }

// ::ng-deep .status-message-info {
//     background-color: lightgreen !important;
// }

// /* ::ng-deep status-message-error {
//     background-color: red !important;
// }

// ::ng-deep status-message-warn {
//     background-color: orange !important;
// }

// ::ng-deep status-message-info {
//     background-color: green !important;
// } */





// .mat-button-active {
//     // color: var(--button-active);
// }
@mixin color($theme) {
    // Get the color config from the theme.
    $color-config: mat.m2-get-color-config($theme);
    // Get the primary color palette from the color-config.
    // $palette: map.get($color-config, accent);
    // .mat-button-active {
    //     // color: mat.get-color-from-palette($primary-palette, 500);
    //     color: mat.get-color-from-palette(map.get($color-config, accent), default);
    // }

    // A15+
    // .mat-mdc-button-active {
    //     // color: mat.get-color-from-palette($primary-palette, 500);
    //     color: mat.get-color-from-palette(map.get($color-config, accent), default);
    // }

    $primary: mat.m2-get-color-from-palette(map.get($color-config, primary),    default);
    $accent:  mat.m2-get-color-from-palette(map.get($color-config, accent),     default);
    $warn:    mat.m2-get-color-from-palette(map.get($color-config, warn),       default);

    $text:    mat.m2-get-color-from-palette(map.get($color-config, foreground), text);


    .mat-mdc-list-item:hover {
        background-color: $accent;
        color:            $text;
    }


    .mat-row:hover {
        background-color: $accent;
    }


    .sidebar-active:not(:hover) {
        // color: mat.get-color-from-palette($primary-palette, 500);
        color: $primary;
        // .get-color-from-palette(map.get($color-config, accent), default);
    }


    
// Color Name	Description
// default	The default color from this palette
// lighter	A lighter version of the color for this palette
// darker	A darker version of the color for this palette
// text	The text color for this palette
// default-contrast	A color that stands out against the this palette's default color
// lighter-contrast	A color that stands out against the this palette's lighter color
// darker-contrast	A color that stands out against the this palette's darker color
// [hue]	The [hue] color for the palette.
// [hue] can be one of: 50, 100, 200, 300, 400, 500, 600, 700, 800, 900, A100, A200, A400, A700
// [hue]-contrast	A color that stands out against the [hue] color for the palette.
// [hue] can be one of: 50, 100, 200, 300, 400, 500, 600, 700, 800, 900, A100, A200, A400, A700
}