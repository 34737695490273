@use "sass:map";

@use 'styles-colours';
@use 'styles-icons';
@use '@angular/material' as mat;

$material-design-icons-font-directory-path: '../../node_modules/material-design-icons-iconfont/dist/fonts/';
@import '../../node_modules/material-design-icons-iconfont/src/material-design-icons';
// @forward '../../node_modules/material-design-icons-iconfont/src/material-design-icons';

// @import url("https://fonts.googleapis.com/icon?family=Material+Icons");


// .mat-app-background {
//     background-color: $light-grey-1;
// }

html, body {
    margin:           0;
    padding:          0;

    min-width:        100%;
    width:            100%;
    max-width:        100%;

    min-height:       100%;
    height:           100%;
    max-height:       100%;
    // background-color: rgba(0,0,0,0.75) !important; 
}


.dense-1 {
    @include mat.all-component-densities(-1);
}
  
.dense-2 {
    @include mat.all-component-densities(-2);
}

.dense-3 {
    @include mat.all-component-densities(-3);
}

.dense-4 {
    @include mat.all-component-densities(-4);
}

html {
    overflow:         hidden;
}


.dialog-transparent {
    background-color: transparent;
    overflow:         hidden;

    // Change Material CSS var
    --mdc-dialog-container-color: transparent;
}

// .dialog {
//     overflow:         hidden !important;
//     background-color: rgba(0,0,0,0.75);
//     color:            white !important;
// }

.mat-dialog-container {
    background-color: transparent;
}

.tabs-wrapper {
    position:         relative;
}


.full-size {
    position:         absolute;
    top:              0;
    right:            0;
    bottom:           0;
    left:             0;
}


// Needed for sidebar narrow - has to be here
.mat-list-item-content {
    padding: 0 !important;
}


#chart2 {
    border-radius:    0;
    max-width:        80%;
    width:            80%;
    height:           100%;
    background-color: rgba(0, 0, 0, 0.54);
}


.angular-google-map-container {    
    background-color: grey;
    display:          inline-block;
    height:           600px;
    margin:           auto;
    width:            100vh;
}

/*.nav-tabs {
    float:            left;
}

.contect-tabs {
    float:            left;
}*/

.nav {
   /* background-color: rgba(0,0,0,0.75); /* black, transparency */
    height:           100%;
    min-height:       100%;
    max-height:       100%;}

.tab-pane {
    overflow-y:       auto;
    overflow-x:       hidden;
    padding-top:      5px;
    background-color: rgb(82, 82, 82);
    height:           100%;
    overflow-y:       auto;
}

.nav-pills {
    float:            left;
    text-align:       center;
    vertical-align:   middle;
    overflow:         overlay;
    resize:           vertical;
    min-width:        88px;
    overflow-x:       hidden;
/*position:fixed;
z-index:10;*/
/*    border-right:     1px solid #ccc;*/
}



// h3 {
//     color:            rgb(255, 255, 255) !important;
// }

.mat-button[disabled] {
    color: rgba(255, 255, 255, 0.25) !important; /* white, transparency */
}


.tab-pane h3 {
    padding-left: 10px;
}
/*.nav-link .md-button:hover {
    /*background-color: rgba(177, 108, 39, 0.75) !important; /* orange hover-over */
/*    background-color: rgba(57, 73, 171, 0.40); /* blue, 60% transparency */
/*}
*/


.nav > li >a:focus, .nav > li >a:hover {
    background-color: rgba(57, 73, 171, 0.40); /* blue, 60% transparency */
}

.tab-content {
    overflow:         hidden;
    height:           100%;
}


/*.contect-pills {
  float: left;
}*/


#map-sidebar-layers {
    overflow:         auto;
    max-width:        230px;
    width:            200px;
    font-size:        smaller;
}

#map-sidebar-layers .md-content {
    background-color: rgba(199, 192, 192, 0.6);
}

#map-sidebar-layers .md-toolbar-tools {
    font-size:        medium;
}


.tab-animation > .tab-content > .tab-pane.active-remove {
    animation:        1s fade-out;
}

.tab-animation > .tab-content > .tab-pane.active-add {
    animation:        1s fade-in;
}

@keyframes fade-in {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@keyframes fade-out {
  from { opacity: 1; }
  to   { opacity: 0; }
}


.red {
  background-color:   red;
}

.li-active {
  background-color:   lightblue;
}


/*.material-icons.md-18 {
    font-size:        18px !important;
    height:           18px !important;
    width:            18px !important;
}

.material-icons.md-24 {
    font-size:        24px !important;
    height:           24px !important;
    width:            24px !important;
}

.material-icons.md-36 {
    font-size:        36px !important;
    height:           36px !important;
    width:            36px !important;
}

.material-icons.md-48 {
    font-size:        48px !important;
    height:           48px !important;
    width:            48px !important;
}

.material-icons.md-dark {
    color:            rgba(0, 0, 0, 0.54);      /* black, 46% transparency */
/*}


.material-icons.md-dark.md-inactive {
    color:            rgba(0, 0, 0, 0.26);      /* black, 74% transparency */ 
/*}

.material-icons.md-light {
    color:            white;
}

.material-icons.md-light.md-inactive {
    color:            rgba(255, 255, 255, 0.3); /* white, 70 transparency */
/*}*/

// .dark-theme {
//     @include angular-material-theme($custom-theme-dark);
// }

// .light-theme {
//     @include angular-material-theme($custom-theme-light);
// }


#chart-avgCallSetupTime {
    width:            50%;
    height:           250px;
    font-size:        11px;
}       

.map-left-checkboxes {
    padding-left:     2vh;
}

/*#map-panel .md-checkbox {
    margin:           5px;
}*/

#map-layers {
    bottom:           55px;
    left:             7px;
    position:         absolute;
}

#map-layers > .uib-dropdown-menu {
    min-width:        230px;
}

#map-layers > .uib-dropdown-menu > li {
    margin-left:      5px;
}

/*#map-panel .md-checkbox.md-icon {
    transform:        scale(.7);
}

#map-panel2 {
    position:         absolute;
    bottom:           55px;
    left:             104px;
    z-index:          5;
    padding-left:     0px;
    padding:          5px;
    text-align:       left;
}*/

.dropdown-menu
{
    border-radius:    2px;
    max-height:       300px; /*Provide height in pixels or in other units as per your layout*/
    overflow-y:       auto;  /*Provide an auto overflow to diaply scroll*/
    font-size:        12px !important;
}


.dropdown-menu md-checkbox .md-label {
    padding-top: 2px !important;
}


.sfn-blue {
    background-color: rgba(57, 73, 171, 0.40); /* blue, 60% transparency */
}


.orange {
    background-color: orange;
}

.yellow {
    background-color: yellow;
}

#graph {
    height:           1200px;
    margin:           auto;
}

.graphs {
    width:            50vw;
    height:           50vw;
}

.chart-main {
  width:              100%;
  height:             500px;
}

.chart-min {
    /*width:            50%;
    height:           40vh;
    /*/
    text-align:       center;
    vertical-align:   middle;
    /*margin-right:     auto;
    margin-left:      auto;*/
}

#chart-sub-outer
{
    width:            100%;
    text-align:       center;
    vertical-align:   middle;
}

.chart-sub {
  width:              30%;
  height:             250px;
  display:            inline-block;
}

@keyframes fadeEffect {
    from {opacity: 0;}
    to   {opacity: 1;}
}

@keyframes fadeEffect {
    from {opacity: 0;}
    to   {opacity: 1;}
}


md-tabs > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active, md-tabs > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active md-icon, md-tabs.md-accent > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active md-icon, md-tabs.md-default-theme.md-accent > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused, md-tabs.md-default-theme.md-accent > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused md-icon {
  color: #fff !important;
}

md-tabs .md-tab[disabled], md-tabs .md-tab[disabled] md-icon {
  color: #000 !important;
}

md-tabs {
    display: flex;
    flex-direction: column;
    background-color: #E9EEF1;
    /*background-color: #E9EEF1;*/
}
.md-tabs-content {
    display: flex;
    flex: 1;
    flex-direction: column;
}
.md-tab-content {
    display: flex;
    flex-direction: row;
    flex: 1;
    overflow-y: scroll;
}

md-container {
    overflow: hidden;
}

md-content {
    overflow-y: hidden !important;
}

/* The side navigation menu */
.sidenav {
    height: 50%; /* 100% Full-height */
    width: 0; /* 0 width - change this with JavaScript */
    /*position: fixed; /* Stay in place */
    z-index: 1; /* Stay on top */
    top: 0;
    right: 0;
    /*background-color: #111; /* Black*/
    background-color: lightblue;
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 60px; /* Place content 60px from the top */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
}

/* The navigation menu links */
.sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    /*color: #818181;*/
    color: black;
    display: block;
    transition: 0.3s
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover, .offcanvas a:focus{
    color: #f1f1f1;
}

/* Position and style the close button (top right corner) */
.closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px !important;
    margin-left: 50px;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
    transition: margin-left 0.3s;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
    .sidenav   {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
}


th .md-button {
    display: block;
    margin-left:  0;
    margin-right: 0;
}


.tab table {
    border-collapse: collapse;
    width: 100%;
}
.tab th, .tab td {
    border: 1px solid #ddd;
    height:30px;

}
.tab th {

    background-color: white;
    color:            rgb(76, 175, 80); /* green */
    font-size:        smaller !important;
    padding-bottom:   2px     !important;
    padding-left:     5px     !important;
    padding-right:    5px     !important;
    padding-top:      2px     !important;
    text-align:       left    !important;
}

/*.tab tr:nth-child(even) {background-color: #f2f2f2;}*/
.tab tr:nth-child(even) {
    background-color: rgb(140, 140, 140);
}

.tab tr:nth-child(odd)  {
    background-color: rgb(166, 166, 166);
}

.tab tr:hover           {
    background-color: #ddd;
}

.tab td {
    font-size:        smaller !important;
    padding-bottom:   0px     !important;
    padding-left:     5px     !important;
    padding-right:    5px     !important;
    padding-top:      0px     !important;
    text-align:       left    !important;
    vertical-align:   middle  !important;
}


#buttonAndTextOuter
{
    text-align:       center;
    vertical-align:   middle;
    width:            100%;
}
.buttonAndTextInner
{
    display:          inline-block;
    // vertical-align:   top;
}

.buttonAndTextInner2
{
    // display:          inline-block;
    float:            right;
    vertical-align:   middle;
}

.buttonAndTextInner3
{
    display:          inline-block;
}




/*.menu {
    margin-left:       10px;
    margin-right:      10px;
}*/

.btn-group.open .btn.dropdown-toggle {
    font-weight:      bold;
}


ul.pagination {
    display:          inline-block;
    padding:          0;
    margin:           0;
}

ul.pagination li {
    display:          inline;
}

ul.pagination li a {
    color:            black;
    float:            left;
    padding:          5px 12px;
    text-decoration:  none;
    transition:       background-color .3s;
    border-radius:    5px;
    font-size:        smaller;
}

ul.pagination li a.active {
    background-color: rgb(57, 73, 171) !important; /*#4CAF50;*/
    border-color:     rgb(57, 73, 171) !important;
    color:            white;
    border-radius:    5px;
}


ul.pagination li a:hover:not(.active) {
    background-color: rgba(57, 73, 171, 0.40); /* blue, 60% transparency #ddd; */
    border-color:     rgba(57, 73, 171, 0.40); /* blue, 60% transparency */
    color:            white;
}

div.pagCtrl {
    text-align:       center;
}


.md-dialog-backdrop {
  z-index: 78;
}

.md-dialog-backdrop ~ .md-dialog-backdrop {
  z-index: 79;
}

md-backdrop.md-dialog-backdrop {
    z-index: 78;
}

.md-dialog-container {
  z-index: 78;
}

.md-dialog-container ~ .md-dialog-container {
  z-index: 80;
}

#plz_wait md-progress-circular.md-default-theme .md-inner .md-left .md-half-circle, md-progress-circular .md-inner .md-left .md-half-circle, md-progress-circular.md-default-theme .md-inner .md-right .md-half-circle, md-progress-circular .md-inner .md-right .md-half-circle {
    border-top-color: white;
}
#plz_wait md-progress-circular.md-default-theme .md-inner .md-gap, md-progress-circular .md-inner .md-gap {
    border-top-color: white;
    border-bottom-color: white;
}
#plz_wait md-progress-circular.md-default-theme .md-inner .md-left .md-half-circle, md-progress-circular .md-inner .md-left .md-half-circle {
    border-left-color: white;
}
#plz_wait md-progress-circular.md-default-theme .md-inner .md-left .md-half-circle, md-progress-circular .md-inner .md-left .md-half-circle {
    border-left-color: white;
}
#plz_wait md-progress-circular.md-default-theme .md-inner .md-right .md-half-circle, md-progress-circular .md-inner .md-right .md-half-circle {
    border-right-color: white;
}

.cdk-global-scrollblock {
    overflow-y: auto !important;
}