@use "sass:map";

// Icons

:root {
    // Tab/Sidebar icons only
    --dashboard-icon-color:       var(#{--dashboard-icon});
    --dashboard-icon-font-size:   18px;
    // --dashboard-icon-font-size:   64px;
    --dashboard-icon-height:      100%;
    // --dashboard-icon-scale:       scale(1.75);
    --dashboard-icon-width:       100%;

    --app-footerHeight:           1.75em;
    --app-headerHeight:           3em;

    --mobilecell-panel-min-width: 16em;
    --wificell-panel-min-width:   24em;

    --standard-padding:           0.5em;
}


.dashboard-icon-style {
    // color:            var(--dashboard-icon-color) !important;
    // color:           var(--tab-icon) white !important;
    transform:                    var(--dashboard-icon-scale) !important;
    width:                        100%;
    // font-size:        var(--dashboard-icon-font-size);
    // height:           var(--dashboard-icon-height) !important;
    // width:            var(--dashboard-icon-width) !important;
}

// .dashboard-icon-style:hover {
//     background-color: var(#{--button-hover});
// }

.mat-menu-item:hover {
    background-color: var(#{--button-hover});
}

.tabbar .mat-tab-label-active {
    background-color: var(#{--button-active});
}


.sidebar-label {
    display:          inline-block;
    margin-left:      15px;
}


.icon-0_25x {
    transform:        scale(0.25);
}

.icon-0_75x {
    transform:        scale(0.75);
}


.icon-1x {
    transform:        scale(1);
}


.icon-1-5d {
    transform:        scale(calc(1/1.5));
}


.icon-1-5x {
    transform:        scale(1.5);
}


.icon-1-75x {
    transform:        scale(1.75);
}


.icon-2x {
    transform:        scale(2);
}


.icon-3x {
    transform:        scale(3);
}


.icon-4x {
    transform:        scale(4);
}


// .mat-button {
//     height:           inherit;
//     width:            inherit;
// }


// .mat-button-active {
//     background-color: var(#{--button-active});
// }

// .mat-tab-label-active {
//     background-color: var(#{--button-active});
// }


// .mat-button:hover {
//     background-color: var(#{--button-hover});
// }

.mat-icon-button:hover {
    background-color: var(#{--button-hover});
}


.mat-badge-content {
    min-width:        22px;
    // width:            auto !important;
}